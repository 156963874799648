import { TFilterPath } from 'components/FilterComponents/FilterComponents.type';
import { invoiceProductItemConverter } from 'data/converters/invoice.converter';
import { materialConverter } from 'data/converters/material.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  materialForQuoteConverter,
  quoteConverter,
} from 'data/converters/quote.converter';
import {
  ticketAssignedConverter,
  ticketCheckListItem,
  ticketComapnyEditConverter,
  ticketCommentConverter,
  ticketConverter,
  ticketDescriptionEditConverter,
  ticketDetailsConverter,
  ticketIssueSubTypeEditConverter,
  ticketIssueTypeEditConverter,
  ticketPrimaryTitleTypeConverter,
  ticketPriorityEditConverter,
  ticketQueueEditConverter,
  ticketReporterCheckConverter,
  ticketReporterEditConverter,
  ticketScheduleDescriptionEditConverter,
  ticketStatusEditConverter,
  ticketTitleEditConverter,
} from 'data/converters/ticket.converters';
import {
  TInvoiceProductItem,
  TInvoiceProductItemUi,
} from 'data/types/invoice.type';
import {
  TMaterial,
  TMaterialPostData,
  TMaterialPostDataUi,
} from 'data/types/material.type';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { TQuotedItemsUi, TQuoteUi } from 'data/types/quote.type';
import {
  TChecklistItem,
  TCreateTicket,
  TCreateTicketUi,
  TEditTicketIssueSubTypeUi,
  TEditTicketUi,
  TPrimaryTitleTypeUI,
  TReporterCheck,
  TReporterCheckUi,
  TTicket,
  TTicketAssigned,
  TTicketAssignedUi,
  TTicketComment,
  TTicketCommentUi,
  TTicketDetails,
  TTicketDetailsUi,
  TTicketUi,
} from 'data/types/ticket.types';

import { authSplitApi } from 'redux/helpers/slice.helpers';
import { TUpdateAttachmentUi } from 'data/types/generalDataTypes';
import { attachmentsUpdateConverter } from 'data/converters/general.converters';
import { patchResponseToToastConverter } from '../../data/converters/responses.converter';
import {
  TPatchResponse,
  TPatchResponseUi,
} from '../../data/types/responses.type';

const GET_TICKETS = 'api/tickets';
const GET_TICKETS_CHECKLIST = 'api/checklist-item';
const GET_ALL_TICKETS = `${GET_TICKETS}/all`;
export const ticketsApi = authSplitApi('tickets', [
  'Tickets',
  'Ticket',
  'TicketComments',
  'TicketMaterialForQuote',
  'TicketMaterialForInvoice',
]).injectEndpoints({
  endpoints: build => ({
    createTicket: build.mutation<TCreateTicketUi, TCreateTicket>({
      query(body) {
        return {
          url: GET_TICKETS,
          method: 'POST',
          body: ticketConverter.toDb(body),
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
    }),
    getAllTicketStatus: build.query<
      string[],
      {
        exactState?: TFilterPath;
        fromState?: TFilterPath;
        fromStatus?: string;
      }
    >({
      query({ exactState, fromState, fromStatus }) {
        const urlWithParams = GET_TICKETS.concat(`/status`);
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(exactState && { exactState: exactState.toUpperCase() }),
            ...(fromState && { fromState: fromState.toUpperCase() }),
            ...(fromStatus && { fromStatus: fromStatus.toUpperCase() }),
          },
        };
      },
    }),
    getAllTicketPriority: build.query<string[], void>({
      query() {
        const urlWithParams = GET_TICKETS.concat(`/priority`);
        return {
          url: urlWithParams,
          method: 'GET',
        };
      },
    }),
    getAllAssignedMembersForFilter: build.query<
      TTicketAssignedUi[],
      {
        search?: string;
        filters?: any;
        selectedTicketQueueIds?: string[];
      }
    >({
      query({ search, selectedTicketQueueIds, filters }) {
        const urlWithParams = GET_TICKETS.concat(`/assignee`);
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(search && { search }),
            ...(filters && { ...filters }),
            ...(selectedTicketQueueIds?.length && { selectedTicketQueueIds }),
          },
        };
      },
      transformResponse: (data: TTicketAssigned[]) => {
        return data.map(ticket => ticketAssignedConverter.fromDb(ticket));
      },
      keepUnusedDataFor: 0,
    }),
    getReporterCheckForTickets: build.query<
      TReporterCheckUi,
      {
        search?: string;
        filters?: any;
        ticketIds?: string[];
      }
    >({
      query({ search, ticketIds, filters }) {
        const urlWithParams = GET_TICKETS.concat(`/reporter-check`);
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(search && { search }),
            ...(filters && { ...filters }),
            ...(ticketIds?.length && { ticketIds }),
          },
        };
      },
      transformResponse: (data: TReporterCheck) => {
        return ticketReporterCheckConverter.fromDb(data);
      },
      keepUnusedDataFor: 0,
    }),
    getAllTickets: build.query<
      TPageableDataWithContentUi<TTicketUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        filters?: any;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, filters, search }) {
        const urlWithParams = GET_ALL_TICKETS.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: [{ type: 'Tickets' }],
      transformResponse: (data: TPageableDataWithContent<TTicket[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(ticket => ticketConverter.fromDb(ticket))
            : [],
        };
      },
    }),
    // Todo: define filters type
    getAllTicketsByTab: build.query<
      TPageableDataWithContentUi<TTicketUi[]>,
      {
        page: number;
        pageSize: number;
        path: TFilterPath;
        sort?: string;
        filters?: any;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, filters, path, search }) {
        const urlWithParams = GET_TICKETS.concat(`/${path}`).concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: [{ type: 'Tickets' }],
      transformResponse: (data: TPageableDataWithContent<TTicket[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(ticket => ticketConverter.fromDb(ticket))
            : [],
        };
      },
    }),

    getTicket: build.query<TTicketDetailsUi, string>({
      query(resourceId) {
        return {
          url: `${GET_TICKETS}/${resourceId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TTicketDetails) =>
        ticketDetailsConverter.fromDb(data),
      providesTags: ['Ticket'],
    }),

    editQueue: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketQueueEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/queue`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),

    editStatus: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, status } = ticketStatusEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/status`,
          method: 'PATCH',
          body: { status },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),

    editPriority: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketPriorityEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/priority`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),

    editTitle: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketTitleEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/title`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),

    editDescription: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketDescriptionEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/description`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),

    editIssueType: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketIssueTypeEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/issue-type`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),

    editIssueSubType: build.mutation<
      TTicketDetailsUi,
      TEditTicketIssueSubTypeUi
    >({
      query(body) {
        const { id, issueSubtypeId, issueTypeId } =
          ticketIssueSubTypeEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/issue-subtype`,
          method: 'PATCH',
          body: { issueSubtypeId, issueTypeId },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),
    editCompany: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketComapnyEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/company`,
          method: 'PATCH',
          body: { value },
        };
      },
      transformResponse: (data: TTicketDetails) =>
        ticketDetailsConverter.fromDb(data),
      invalidatesTags: ['Tickets', 'Ticket'],
    }),
    editScheduleDescription: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketScheduleDescriptionEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/next-action-item-description`,
          method: 'PATCH',
          body: { value },
        };
      },
      transformResponse: (data: TTicketDetails) =>
        ticketDetailsConverter.fromDb(data),
      invalidatesTags: ['Ticket'],
    }),
    editReporter: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = ticketReporterEditConverter.toDb(body);
        return {
          url: `${GET_TICKETS}/${id}/reporter`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Ticket'],
    }),

    updateQueueThroughTicket: build.mutation<
      void,
      { queueId: string; ticketIds?: string[]; filters?: any }
    >({
      query({ queueId, ticketIds, filters }) {
        return {
          url: GET_TICKETS.concat('/queue'),
          method: 'PATCH',
          params: {
            ...(filters && { ...filters }),
          },
          body: {
            queueId,
            ticketIds,
          },
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
    }),
    updateStatusThroughTicket: build.mutation<
      { failedIds: string[] },
      { status: string; ticketIds?: string[]; filters?: any }
    >({
      query({ status, ticketIds, filters }) {
        return {
          url: GET_TICKETS.concat('/status'),
          method: 'PATCH',
          params: {
            ...(filters && { ...filters }),
          },
          body: {
            status,
            ticketIds,
          },
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
      transformResponse: (data: { failedIds: string[] }) => data,
    }),
    bulkAssigneeUpdate: build.mutation<
      { failedIds: string[] },
      {
        assigneeId: string;
        ticketIds?: string[];
        filters?: any;
        nextStatus: string;
        comment: {
          text: string;
          replyToCustomer: boolean;
        };
      }
    >({
      query({ assigneeId, ticketIds, filters, nextStatus, comment }) {
        return {
          url: GET_TICKETS.concat('/assign'),
          method: 'PATCH',
          params: {
            ...(filters && { ...filters }),
          },
          body: {
            assigneeId,
            ticketIds,
            comment,
            nextStatus,
          },
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
      transformResponse: (data: { failedIds: string[] }) => data,
    }),
    bulkReAssigneeUpdate: build.mutation<
      { failedIds: string[] },
      {
        assigneeId: string;
        ticketIds?: string[];
        filters?: any;
        comment: {
          text: string;
          replyToCustomer: boolean;
        };
      }
    >({
      query({ assigneeId, ticketIds, filters, comment }) {
        return {
          url: GET_TICKETS.concat('/reassign'),
          method: 'PATCH',
          params: {
            ...(filters && { ...filters }),
          },
          body: {
            assigneeId,
            ticketIds,
            comment,
          },
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
      transformResponse: (data: { failedIds: string[] }) => data,
    }),
    deleteTicketById: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: GET_TICKETS.concat(`/${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
    }),
    deleteTicketsByIds: build.mutation<
      void,
      {
        ids: React.Key[];
        filters?: any;
        search?: string;
      }
    >({
      query({ ids, filters, search }) {
        return {
          url: GET_TICKETS,
          method: 'DELETE',
          params: {
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
          body: ids,
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
    }),
    createTicketCheckList: build.mutation<
      void,
      {
        description: string;
        parentId: string;
      }
    >({
      query({ description, parentId }) {
        return {
          url: GET_TICKETS_CHECKLIST,
          method: 'POST',
          body: {
            description,
            parentId,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateTicketCheckListCheck: build.mutation<
      void,
      {
        checklistItemId: string;
      }
    >({
      query({ checklistItemId }) {
        return {
          url: GET_TICKETS_CHECKLIST.concat(`/${checklistItemId}/check`),
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateTicketCheckListUnCheck: build.mutation<
      void,
      {
        checklistItemId: string;
      }
    >({
      query({ checklistItemId }) {
        return {
          url: GET_TICKETS_CHECKLIST.concat(`/${checklistItemId}/uncheck`),
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateTicketCheckListDescription: build.mutation<
      void,
      {
        checklistItemId: string;
        value: string;
      }
    >({
      query({ checklistItemId, value }) {
        return {
          url: GET_TICKETS_CHECKLIST.concat(`/${checklistItemId}/description`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateTicketCheckListOrder: build.mutation<
      TChecklistItem[],
      {
        ticketId: string;
        checklistItemId: string;
        value: number;
      }
    >({
      query({ checklistItemId, value }) {
        return {
          url: GET_TICKETS_CHECKLIST.concat(`/${checklistItemId}/order`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      async onQueryStarted({ ticketId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const convertedData = data.map(checklistItem =>
          ticketCheckListItem.fromDb(checklistItem),
        );
        dispatch(
          ticketsApi.util.updateQueryData('getTicket', ticketId, draft => {
            Object.assign(draft.checklistItems, convertedData);
          }),
        );
      },
    }),
    deleteTicketCheckListById: build.mutation<
      void,
      {
        checklistItemId: string;
      }
    >({
      query({ checklistItemId }) {
        return {
          url: GET_TICKETS_CHECKLIST.concat(`/${checklistItemId}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateTicketAssigne: build.mutation<
      void,
      {
        ticketId: string;
        assigneeId: string;
        nextStatus: string;
        comment: {
          text: string;
          replyToCustomer: boolean;
        };
      }
    >({
      query({ ticketId, assigneeId, nextStatus, comment }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/assign`),
          method: 'PATCH',
          body: {
            assigneeId,
            nextStatus,
            comment,
          },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket', 'TicketComments'],
    }),
    updateTicketReAssigne: build.mutation<
      TPatchResponseUi,
      {
        ticketId: string;
        assigneeId: string;
        comment: {
          text: string;
          replyToCustomer: boolean;
        };
        keepNSI: boolean;
      }
    >({
      query({ ticketId, assigneeId, comment, keepNSI }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/reassign`),
          method: 'PATCH',
          body: {
            assigneeId,
            comment,
            keepNSI,
          },
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['Tickets', 'Ticket', 'TicketComments'],
    }),
    startTicketWork: build.mutation<
      void,
      {
        ticketId: string;
        nextStatus: string;
      }
    >({
      query({ ticketId, nextStatus }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/start-work`),
          method: 'PATCH',
          body: {
            nextStatus,
          },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket'],
    }),
    resolveTicket: build.mutation<
      void,
      {
        ticketId: string;
        nextStatus: string;
        comment: {
          text: string;
          replyToCustomer: boolean;
        };
      }
    >({
      query({ ticketId, nextStatus, comment }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/resolve`),
          method: 'PATCH',
          body: {
            nextStatus,
            comment,
          },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket', 'TicketComments'],
    }),
    closeTicket: build.mutation<
      void,
      {
        ticketId: string;
        nextStatus: string;
      }
    >({
      query({ ticketId, nextStatus }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/close`),
          method: 'PATCH',
          body: {
            nextStatus,
          },
        };
      },
      invalidatesTags: ['Tickets', 'Ticket', 'TicketComments'],
    }),
    scheduleTicket: build.mutation<
      TPatchResponseUi,
      {
        ticketId: string;
        scheduleTime: string;
        endTime: string;
        description: string;
        status: string;
        comment: {
          text: string;
          replyToCustomer: boolean;
        };
        createCalendarItem: boolean;
      }
    >({
      query({
        ticketId,
        status,
        description,
        scheduleTime,
        comment,
        endTime,
        createCalendarItem,
      }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/schedule`),
          method: 'PATCH',
          body: {
            nextStatus: status,
            description,
            scheduleTime,
            comment,
            endTime,
            createCalendarItem,
          },
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['Tickets', 'Ticket', 'TicketComments'],
    }),
    reScheduleTicket: build.mutation<
      TPatchResponseUi,
      {
        ticketId: string;
        scheduleTime: string;
        description: string;
        status: string;
        endTime: string;
        createCalendarItem: boolean;
        comment: {
          text: string;
          replyToCustomer: boolean;
        };
      }
    >({
      query({
        ticketId,
        status,
        description,
        scheduleTime,
        comment,
        endTime,
        createCalendarItem,
      }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/reschedule`),
          method: 'PATCH',
          body: {
            nextStatus: status,
            description,
            scheduleTime,
            comment,
            endTime,
            createCalendarItem,
          },
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['Tickets', 'Ticket', 'TicketComments'],
    }),
    quickScheduleTicket: build.mutation<
      TPatchResponseUi,
      {
        ticketId: string;
      }
    >({
      query({ ticketId }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/quick-schedule`),
          method: 'PATCH',
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['Tickets', 'Ticket', 'TicketComments'],
    }),
    getTicketComments: build.query<
      TTicketCommentUi[],
      {
        ticketId: string;
      }
    >({
      query({ ticketId }) {
        const urlWithParams = GET_TICKETS.concat(`/${ticketId}/comments`);
        return {
          url: urlWithParams,
          method: 'GET',
        };
      },
      providesTags: ['TicketComments'],
      transformResponse: (data: TTicketComment[]) => {
        return data.map(comment => ticketCommentConverter.fromDb(comment));
      },
      keepUnusedDataFor: 0,
    }),
    saveTicketComment: build.mutation<
      void,
      {
        ticketId: string;
        text: string;
        replyToCustomer: boolean;
        additionalRecipients?: string[];
        isHtml: boolean;
      }
    >({
      query({ ticketId, text, replyToCustomer, additionalRecipients, isHtml }) {
        return {
          url: GET_TICKETS.concat(`/${ticketId}/comment`),
          method: 'PATCH',
          body: {
            text,
            replyToCustomer,
            additionalRecipients,
            isHtml
          },
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    editTicketComment: build.mutation<
      void,
      {
        commentId: string;
        value: string;
      }
    >({
      query({ commentId, value }) {
        return {
          url: GET_TICKETS.concat(`/comments/${commentId}/text`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    deleteTicketCommentById: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: GET_TICKETS.concat(`/comments/${commentId}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    markUsReadTicketComment: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: GET_TICKETS.concat(`/comments/${commentId}/mark-as-read`),
          method: 'PATCH',
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    ticketExportCSV: build.mutation<
      Blob,
      {
        ids: React.Key[];
        path: string;
        filters?: any;
        search?: string;
      }
    >({
      query({ ids, filters, search, path }) {
        return {
          url: GET_TICKETS.concat(`/${path}/export`),
          method: 'POST',
          params: {
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
          responseHandler: response => response.text(),
          ...(ids.length && { body: ids }),
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
    }),
    ticketMaterialsExport: build.mutation<Blob, string>({
      query(ticketId) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/export`,
          method: 'POST',
          responseHandler: response => response.text(),
        };
      },
    }),
    createMaterialTicket: build.mutation<
      TMaterialPostDataUi,
      TMaterialPostData & {
        ticketId: string;
      }
    >({
      query(body) {
        return {
          url: `${GET_TICKETS}/${body.ticketId}/materials`,
          method: 'PATCH',
          body: materialConverter.toDb(body),
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    deleteMaterialTicket: build.mutation<
      void,
      { ticketId: string; materialId: string }
    >({
      query({ ticketId, materialId }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}`,
          method: 'Delete',
        };
      },
      invalidatesTags: ['Ticket', 'Tickets'],
    }),
    updateMaterialNameTicket: build.mutation<
      void,
      {
        materialId: string;
        ticketId: string;
        value: string;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/name`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateMaterialQtyTicket: build.mutation<
      void,
      {
        materialId: string;
        ticketId: string;
        value: number;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/quantity`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateMaterialCostTicket: build.mutation<
      void,
      {
        materialId: string;
        ticketId: string;
        value: number;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/cost`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateMaterialPriceTicket: build.mutation<
      void,
      {
        materialId: string;
        ticketId: string;
        value: number;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/price`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateMaterialStatusTicket: build.mutation<
      void,
      {
        materialId: string;
        ticketId: string;
        value: string;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/status`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket', 'Tickets'],
    }),
    updateMaterialCategoryTicket: build.mutation<
      void,
      {
        materialId: string;
        ticketId: string;
        value: string;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/category`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateMaterialDescriptionTicket: build.mutation<
      void,
      {
        materialId: string;
        ticketId: string;
        value: string;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/description`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    updateMaterialOrderTicket: build.mutation<
      TTicketDetails,
      {
        materialId: string;
        ticketId: string;
        value: number;
      }
    >({
      query({ materialId, ticketId, value }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials/${materialId}/order`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      async onQueryStarted({ ticketId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const convertedData = ticketDetailsConverter.fromDb(data);
        dispatch(
          ticketsApi.util.updateQueryData('getTicket', ticketId, draft => {
            Object.assign(draft, convertedData);
          }),
        );
      },
    }),
    getTicketMaterialsForInvoice: build.query<
      TInvoiceProductItemUi[],
      { ticketId?: string }
    >({
      query({ ticketId }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials-for-invoice`,
          method: 'GET',
        };
      },
      providesTags: ['TicketMaterialForInvoice'],
      transformResponse: (data: TInvoiceProductItem[]) => {
        return data.map(invoiceItem =>
          invoiceProductItemConverter.fromDb(invoiceItem),
        );
      },
      keepUnusedDataFor: 0,
    }),
    updateTicketAttachments: build.mutation<
      void,
      {
        ticketId: string;
        attachments: TUpdateAttachmentUi[];
      }
    >({
      query({ ticketId, attachments }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/attachments`,
          method: 'PATCH',
          body: attachmentsUpdateConverter.toDb(attachments),
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    deleteTicketAttachment: build.mutation<
      void,
      {
        ticketId: string;
        attachmentId: string;
      }
    >({
      query({ ticketId, attachmentId }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/attachments/${attachmentId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    getTicketMaterialsForQuote: build.query<
      TQuotedItemsUi[],
      { ticketId?: string }
    >({
      query({ ticketId }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/materials-for-quote`,
          method: 'GET',
        };
      },
      providesTags: ['TicketMaterialForQuote'],
      transformResponse: (data: TMaterial[]) => {
        return data.map(quoteItem =>
          materialForQuoteConverter.fromDb(quoteItem),
        );
      },
      keepUnusedDataFor: 0,
    }),
    generateTicketQuote: build.mutation<Blob, TQuoteUi & { ticketId: string }>({
      query(body) {
        const { ticketId, ...payload } = body;
        return {
          url: `${GET_TICKETS}/${ticketId}/quote`,
          method: 'POST',
          body: quoteConverter.toDb(payload),
          responseHandler: response =>
            response.status === 200 ? response.blob() : response.json(),
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    editTicketDevice: build.mutation<TTicketDetailsUi, TEditTicketUi>({
      query(body) {
        const { id, value } = body;
        return {
          url: `${GET_TICKETS}/${id}/device`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    generateTicketTitle: build.mutation<
      void,
      {
        ticketId: string;
      }
    >({
      query({ ticketId }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/title/generate`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Ticket', 'Tickets'],
    }),
    removeGeneratedTicketTitle: build.mutation<
      void,
      {
        ticketId: string;
      }
    >({
      query({ ticketId }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/title/generate`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Ticket', 'Tickets'],
    }),
    selectTicketPrimaryTitle: build.mutation<
      void,
      {
        ticketId: string;
        primaryTitleType: TPrimaryTitleTypeUI;
      }
    >({
      query({ ticketId, primaryTitleType }) {
        return {
          url: `${GET_TICKETS}/${ticketId}/title/primary`,
          method: 'PATCH',
          body: {
            value: ticketPrimaryTitleTypeConverter.toDb(primaryTitleType),
          },
        };
      },
      invalidatesTags: ['Ticket', 'Tickets'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTicketCommentsQuery,
  useTicketExportCSVMutation,
  useSaveTicketCommentMutation,
  useEditTicketCommentMutation,
  useDeleteTicketCommentByIdMutation,
  useCreateTicketMutation,
  useGetAllTicketsByTabQuery,
  useGetAllTicketsQuery,
  useGetTicketQuery,
  useGetAllAssignedMembersForFilterQuery,
  useGetReporterCheckForTicketsQuery,
  useEditQueueMutation,
  useEditStatusMutation,
  useEditPriorityMutation,
  useEditTitleMutation,
  useEditDescriptionMutation,
  useEditIssueTypeMutation,
  useEditIssueSubTypeMutation,
  useEditCompanyMutation,
  useEditScheduleDescriptionMutation,
  useEditReporterMutation,
  useGetAllTicketStatusQuery,
  useGetAllTicketPriorityQuery,
  useUpdateQueueThroughTicketMutation,
  useUpdateStatusThroughTicketMutation,
  useBulkAssigneeUpdateMutation,
  useBulkReAssigneeUpdateMutation,
  useDeleteTicketByIdMutation,
  useDeleteTicketsByIdsMutation,
  useCreateTicketCheckListMutation,
  useUpdateTicketCheckListCheckMutation,
  useUpdateTicketCheckListUnCheckMutation,
  useUpdateTicketCheckListDescriptionMutation,
  useUpdateTicketCheckListOrderMutation,
  useDeleteTicketCheckListByIdMutation,
  useUpdateTicketAssigneMutation,
  useUpdateTicketReAssigneMutation,
  useStartTicketWorkMutation,
  useCloseTicketMutation,
  useResolveTicketMutation,
  useScheduleTicketMutation,
  useReScheduleTicketMutation,
  useQuickScheduleTicketMutation,
  useTicketMaterialsExportMutation,
  useCreateMaterialTicketMutation,
  useDeleteMaterialTicketMutation,
  useUpdateMaterialNameTicketMutation,
  useUpdateMaterialQtyTicketMutation,
  useUpdateMaterialCostTicketMutation,
  useUpdateMaterialPriceTicketMutation,
  useUpdateMaterialStatusTicketMutation,
  useUpdateMaterialCategoryTicketMutation,
  useUpdateMaterialDescriptionTicketMutation,
  useUpdateMaterialOrderTicketMutation,
  useLazyGetTicketMaterialsForQuoteQuery,
  useGenerateTicketQuoteMutation,
  useLazyGetTicketMaterialsForInvoiceQuery,
  useUpdateTicketAttachmentsMutation,
  useDeleteTicketAttachmentMutation,
  useMarkUsReadTicketCommentMutation,
  useEditTicketDeviceMutation,
  useGenerateTicketTitleMutation,
  useRemoveGeneratedTicketTitleMutation,
  useSelectTicketPrimaryTitleMutation,
} = ticketsApi;
